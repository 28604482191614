.Pages {
    /* display: flex;
    flex: 1; */
    margin-top: 0px;
    /* width: 100%;
    height: auto;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.Pages--wallet {
    border-radius: 8px;
    width: 100%;
    float: left;

}

.history-lable {
    font-size: 30px !important;
    margin: 31%;
    margin-top: 12%;
}

.Pages.Pages--wallet {
    margin-top: 0px;
}

@media (max-width:991px) {
    .Pages--wallet {
        width: 100%;
        float: left;
        height: auto;
        padding-right: 0px;
    }
}