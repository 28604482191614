
  .chart-img{
    background: rgba(21, 22, 29, 1);
    border-radius: 8px;
    border: 1px solid #222121;
    padding: 20px;
    margin-bottom: 20px;
  }
  .chart-img .canvas{
    height: 500px!important;
  }
.dashb h4{
  text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
    margin-top: 25px;
    margin-bottom: 15px;
}

.chart-btn button{
  border: none;
  outline: none;
  color: #9ca0ac;
  background-color: transparent;
  margin:  0 5px;
}

@media (min-width: 320px) and (max-width: 991px){
 
.box-img {
  position: relative;
  background: #191919;
  padding: 0;
  border-radius: 10px;
}
.box-flex {
  border-radius: 8px;
  background: #3A3C40;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 9;
  top: 0;
  left: 0;
  margin-bottom: 20px;
}
#features .head h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
#statics .des h2 {
  color: #DEDEDE;
  font-size: 22px;
  font-weight: 600;
}
#home-banner::after{
  content: none;
}

}


