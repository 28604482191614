.FormInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;
}

.FormInputContainer__title {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: normal;
    color: rgba(226, 218, 240, 1);
    margin-bottom: 5px;
    margin-left: 15px;
}

.FormInputContainer__input-container {
    display: flex;
    flex: 1;
    width: 100%;
    color: white;
    background-color: none
}

.FormInputContainer__error {
    font-size: 13px;
    font-weight: normal;
    color: #ff1d25;
    min-height: 25px;
    margin-top: 5px;
    text-align: left;
}

.BottomBorderInput {
    border: none;
    outline: none;
  
    font-size: 16px;
    font-weight: 300;
    background-color: rgba(36, 36, 48, 1);
    color: white;
    border-radius: 8px;
    height: 48px;
    padding: 0 15px;
}

.BottomBorderInput::placeholder {
    color: #fff;
    width: 100%;
    /* font-family: "helveticaneuethin"; */
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff url('/img/icons/arrow-down@2x.png') no-repeat;
    background-size: 20px;
    background-position: right 10px center;
    padding-right: 30px;
}

/* For IE10 */
select::-ms-expand {
    display: none;
}