.StakesPage {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;
    -webkit-backdrop-filter: blur(30px);
   
}

.StakesPage__detail-view {
    overflow-y: auto;
    padding: 10px;
}

.StakesPage__header-buttons {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    bottom: 0;
    z-index: 99;
    left: 0;
    padding: 10px;
    width: 100%;
    justify-content: center;
}

.metatron-note {
    color: red !important;
}

.StakesPage__header-buttons .GhostButton:last-child {
    background: rgba(53, 58, 84, 1);
}