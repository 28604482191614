.GradientButton {
    display: block;
    padding: 15px 45px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    background: rgba(204, 94, 255, 1)!important;
    color: #fff!important;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
    opacity: 1;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    width: 50%;
    margin: 0 auto;
    font-weight: 400;
}
.DeployContractContent  .GradientButton{
    margin: 20px auto!important
} 



.newGradientButton {
    display: block;
    border-radius: 4px;
    padding: 15px 45px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    margin: auto;
    background: transparent linear-gradient(90deg, #00FFFF 0%, #4ED0FF 27%, #E370FF 84%, #E26CFF 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.GradientButton:active {
    box-shadow: 0 0 25px rgba(33, 237, 186, 0.25);
}

.GradientButton:active {
    box-shadow: 0 0 25px rgba(33, 237, 186, 0.25);
}

.GradientButton--is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
   }

