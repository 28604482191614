.metatron-note {
    color: red !important;
}
.customds.dropdown
{
    float: none!important;
    bottom: 0;
    border-radius: 8px;
    margin-bottom: 10px;
}
.customds.dropdown .dd-button.dropdown-toggle {
    border-radius: 4px;
    padding: 15px;
    /* padding-right: 10px; */
    text-align: left;
    /* margin-bottom: 20px; */
    font-size: 16px;
    width: 100%;
    background: rgba(36, 36, 48, 1);
    border-radius: 0;
    /* border-bottom: 1px solid #585858; */
    box-shadow: none;
    color: #fff;
    border-radius: 8px;
}

.customds.dropdown .dd-menu
{
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    padding: 0;
}
.customds.dropdown .dd-menu li{
    padding: 10px;
    font-size: 14px;
}
.customds.dropdown .imgClass
{
    width: 10px;
}

.TxForm .customds.dropdown .dd-menu {
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    padding: 0;
    /* background: transparent; */
    top: 10px!important;
    left: 0!important;
    box-shadow: none;
}