#home-banner {
    padding: 40px 0 0;
    position: relative;
}

#home-banner::after {
    content: '';
    position: absolute;
    bottom: 150px;
    /* background-image: url('/img/home/banner1.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 60%;
}

#home-banner .banner-des {
    text-align: center;
}

#home-banner .banner-des h2 {
    color: rgba(194, 194, 194, 1);
    font-size: 40px;
    /* font-weight: 700; */
    /* letter-spacing: 2px; */
    line-height: 55px;
    margin-top: 45px;
}

#home-banner .banner-des p {
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-weight: 300;
    max-width: 52%;
    margin: 0 auto;
    padding: 15px 0;
}

#home-banner .banner-des .btn-des {
    padding: 30px 0;
}

#home-banner .banner-des .btn-des .btn1 {
    background: rgba(204, 94, 255, 1);
    border-radius: 15px;
    outline: none;
    border: none;
    padding: 15px 30px;
    font-weight: 400;
    color: #fff !important;
    font-size: 14px;
}

#home-banner .banner-des .btn-des .btn2 {
    border: 1px solid #373b55;
    background: #373b55;
    border-radius: 15px;
    outline: none;
    padding: 15px 30px;
    font-weight: 400;
    color: #fff !important;
    font-size: 14px;
    margin-left: 10px;
}

a.btn124 {
    background: rgba(204, 94, 255, 1);
    border-radius: 15px;
    outline: none;
    border: none;
    padding: 15px 30px;
    font-weight: 400;
    color: #fff !important;
    font-size: 14px;
    margin-left: 10px;
}


#features {
    padding: 120px 0 40px;
}

.align-item-center {
    align-items: center;
}

#features .head h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    background: linear-gradient(76.3deg, #A02DE6 3.99%, #DD8282 90.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#features .head p {
    color: rgba(226, 218, 240, 1);
    font-size: 16px;
    font-weight: 400;
}

#features .h-100 {
    height: 100%;
    padding: 10px 0;
}

#features .box {
    border-radius: 8px;
    box-shadow: 10px 32px 72px 0px rgba(51, 51, 51, 0.03);
    padding: 25px;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.28);
    background: rgba(36, 36, 48, 1);
}


#features .box h5 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
}

#features .box p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin: 0 auto;
    padding: 10px 0;
}

#statics {
    padding: 100px 0;
}

#statics .img {
    position: relative;
    border-radius: 22.291px;
    border: 0.697px solid #3A3C40;
    background: #212327;
}

#statics .img img {
    width: 100%;
    position: relative;
}

#statics .des h2 {
    background: linear-gradient(76.3deg, #A02DE6 3.99%, #DD8282 90.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 42px;
    font-weight: 600;
}

#statics .des p {
    color: rgba(226, 218, 240, 1);
    font-size: 16px;
    font-weight: 400;
}

#statics .des ul {
    margin: 0;
    padding: 0 0 20px;
}

#statics .des ul li {
    color: rgba(226, 218, 240, 1);
    font-size: 16px;
    font-weight: 400;

    padding-bottom: 15px;
    font-weight: 400;
    list-style-position: outside;
    display: flex;
    align-items: flex-start;
}

#statics .des ul li::before {
    content: '\f111';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    font-size: 14px;
    color: #fff;
    flex: none;
    display: block;
    width: 16px;
    position: relative;
    top: 4px;
    margin-right: 8px;
}

#statics .des .btn1 {
    background: rgba(204, 94, 255, 1);
    border-radius: 15px;
    outline: none;
    border: none;
    padding: 15px 30px;
    font-weight: 400;
    color: #fff !important;
    font-size: 14px;
}

#footer {
    background: rgba(21, 22, 29, 1);
    padding: 40px 0 10px;
}

#footer .logo-txt p {
    color: #999;
    font-size: 14px;
    padding: 10px 0;
}

#footer .logo-txt ul {
    margin: 0;
    padding: 0;
}

#footer .logo-txt ul li {
    list-style: none;
    display: inline-block;
    padding-right: 25px;
    transition: .3s;
}

#footer .logo-txt ul li:hover {
    transform: scale(1.2);
}

#footer .logo-txt ul li a {
    color: #fff;
}

#footer .logo-txt ul li i {
    font-size: 20px;
}

#footer .logo-txt ul li a img {
    width: 18px;
    margin-top: -5px;
}

#footer .links h5 {
    color: #fff;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 15px;
}

#footer .links ul {
    margin: 0;
    padding: 0;
}

#footer .links ul li {
    list-style: none;
    padding-bottom: 10px;
}

#footer .links ul li a {
    color: rgba(255, 255, 255, 0.48);
    font-size: 14px;
    font-weight: 400;
}

#footer .links p {
    color: #999;
    font-size: 14px;
    font-weight: 300;
}

#footer .links .input-field {
    position: relative;
}

#footer .links .input-field a {
    color: #c71bff;
}

#footer .links .input-field input {
    width: 100%;
    height: 40px;
    position: relative;
    border-radius: 25px;
    background: #474747;
    color: #fff;
    font-weight: 300;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 0 15px;
}

#footer .links .input-field input::placeholder {
    color: #969696;
    font-weight: 400;
}

#footer .links .input-field img {
    position: absolute;
    right: 10px;
    width: 25px;
    top: 8px;
}

#footer .copyright {
    margin-top: 40px;
}

#footer .copyright p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

#footer .copyright p a {
    color: inherit;
}

.box-img {
    position: relative;
    background: #191919;
    padding: 100px 60px 40px;
    border-radius: 10px;
    width: 100%;
}

.box-flex {
    border-radius: 8px;
    background: #3A3C40;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    position: absolute;
    z-index: 9;
    top: 25px;
    left: 70px;
}

.box-flex img {
    margin-right: 10px;
    height: 40px;
}

.box-flex h6 {
    color: #9CA0AC;
    margin: 0;
}

.box-flex span {
    color: #4AFF92;
}

.box-flex p {
    color: #D4D4D4;
    font-weight: 500;
    margin: 0;
}

.lbank-img {
    position: absolute;
    right: 75px;
    top: 45px;
}

.lbank-img a img {
    width: 40px;
}

@media (min-width: 320px) and (max-width: 991px) {
    header .header {
        display: block;
        padding: 0 10px 40px;
    }

    header .header .btns-box {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .navbar ul li {
        padding: 6px 0;
    }

    header .header .btns-box .custom-top {
        margin-top: 20px;
    }

    header .header .navs>ul>li>a {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        color: #fff;
        padding: 25px 5px;
        margin: 0 3px;
        cursor: pointer;
    }

    #home-banner .banner-des h2 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
        line-height: 30px;
    }

    .head {
        text-align: center;
    }

    #features .container .col {
        width: 100% !important;
        flex: auto;
    }

    #home-banner .banner-des .btn-des {
        padding: 15px 0;
        display: block;
    }

    #home-banner .banner-des .btn-des a.btn1 {
        margin: 0 !important;
        margin-bottom: 10px !important;
    }

    .navbar-brand span {
        top: 16px;
        left: 70px;
    }

    #home-banner {
        padding: 15px 0 0 !important;
    }

    #home-banner .banner-des .btn-des a.btn2 {
        margin: 0 !important;
        margin-top: 10px !important;
    }

    #home-banner .banner-des .btn-des a.btn124 {
        margin: 0 !important;
    }

    #home-banner .banner-des p {
        max-width: 100%;
        font-size: 18px;
    }

    #features .head h2 {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
    }

    #features {
        padding: 40px 0 0;
    }

    #statics {
        padding: 25px 0 50px;
    }

    #statics .des {
        padding-left: 0;
        padding-top: 20px;
    }

    #statics .des h2 {
        color: #DEDEDE;
        font-size: 26px;
        font-weight: 600;
    }

    .links {
        margin-bottom: 15px;
    }

    #features .box {
        text-align: center;
    }

    #features .box h5 {
        margin-bottom: 0;
    }

    #footer {
        padding: 10px 0;
    }

    .lbank-img {
        display: none;
    }

    .btns-box {
        margin-top: 0 !important;
    }

    .navbar .navbar-brand img {
        width: 120px !important;
    }

    .wallet-page .unlock-img {
        display: none;
    }
}