.DepositStakeModal {
    display: flex;
    flex-direction: column;
    background: rgba(21, 22, 29, 0.87);
    border-radius: 8px!important;
    width: 800px;
    padding: 50px 20px;
    box-sizing: border-box;
}

.DepositStakeModal__title {
    font-size: 26px;
    text-align: center;
    color: white;
    margin-bottom: 0;
    font-weight: 600;
}

.DepositStakeModal__title img {
    margin-right: 10px;
}

.rtdepositestake {
    width: 100%;
    background: transparent;
    margin-top: 10px;
    float: none;
}

.rtdepositestake .dd-button {
    display: block;
    position: static;
    background: transparent;
    border: 1px solid #687399;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    border: 2px dashed #B5B5B5;
    border-radius: 30px;
}

.rtdepositestake.for-active .dd-button {
    display: block;
    background: transparent;
    background-color: transparent;
    border: 0px solid #687399;
    width: 100%;
    border-radius: 4px;
    border-radius: 2px;
    border: 0px dashed #B5B5B5;
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
}

.for-active a.StakePurposeSelectorItem {
    display: block;
    position: static;
    background: rgba(36, 36, 48, 1);
    /* border: 1px solid #687399; */
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    padding: 15px;
}

.for-active a.StakePurposeSelectorItem.StakePurposeSelectorItem--is-selected {
    border: 1px solid #893dfc !important;
}


.internaldepositeclass .StakePurposeSelectorItem {
    border: none;
    margin: 0;
    padding: 5px;
    display: block;
}

.imgClass {
    top: 43% !important;
}

.rtdepositestake .dd-menu {
    width: 100%;
    border-radius: 4px;
    border: none;
    padding: 0px;
    background: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    top: 0!important;
    left: 0!important;
}
.rtdepositestake .dd-menu li a{
    border: none!important;
}

.rtdepositestake .dd-menu li {
    padding: 0!important
}

.rtdepositestake .dd-menu li a {
    margin: 0;
    text-align: left;
    padding: 11px;
    border: none;
    border-bottom: 1px solid #68739969;
    border-radius: 0;
}

.StakePurposeContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.StakePurposeContainer__instructions {
    font-size: 16px;
    font-weight: normal;
    line-height: 21px;
    color: #9CA0AC;
    text-decoration: none;
    cursor: inherit;
    margin-bottom: 40px;
    font-weight: 400;
}

.StakePurposeContainer__footer {
    width: 60%;
    margin: 0 auto;
    margin-top: 60px;
}
.DepositStakeModal .modal-img{
    width: 30px;
    margin: 0 auto;
}

@media (max-width: 991px) {
    .DepositStakeModal {
        width: 100%;
    }
}