#tokens-page #tokens-page .tok-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#tokens-page .tok-flex h6 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}
#tokens-page .tok-flex svg{
    width: 18px;
    cursor: pointer;
}
.sidecontainer #tokens-page .container-fluid
{
    padding: 0;
}
#tokens-page .tok-flex img {
    cursor: pointer;
}

#tokens-page .container-fluid
{
    padding: 0;
}
#tokens-page .token-box{
    background: #000;
    border: 1px solid #242424;
    display: block;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

#tokens-page .lefty-flex {
    display: flex;
    justify-content: start;
    align-items: center;
}

#tokens-page .lefty-flex img {
    width: 20px;
    margin-right: 10px;
}

#tokens-page .lefty-flex h6 {
    margin: 0;
    font-size: 14px;
    padding-bottom: 5px;
}

#tokens-page .lefty-flex h6 span {
    color: #9F9F9F;
    font-size: 14px;
}

#tokens-page .lefty-flex p {
    margin: 0;
    /* color: #9F9F9F; */
    font-size: 14px;
}

#tokens-page .lefty-flex p b {
    color: #93FFBE;
}

.righty {
    position: relative;
    /* text-align: right; */
    display: none;
    padding-right: 30px;
}

#tokens-page .righty h4 {
    margin: 0;
    color: #CDCDCD;
    font-size: 16px;
}

#tokens-page .modal-content {
    background: rgba(21, 22, 29, 0.87);
    border-radius: 8px;
}

#tokens-page .modal-content i {
    color: #fff;
    text-align: end;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}

#tokens-page .modal-content .modal-title {
    text-align: center;
    margin-top: 40px;
     
}

#tokens-page .modal-content .modal-title img {
    width: 50px;
}

#tokens-page .modal-content .modal-title h5 {
    color: #FFF;
    margin-top: 10px;
    font-size: 20px;
}

#tokens-page .form-box {
    padding: 20px;
}

#tokens-page .form-box .input-field {
    margin-bottom: 20px;
}

#tokens-page .form-box .input-field span {
    display: block;
    color: rgba(226, 218, 240, 1);
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 400;
    margin-left: 15px;
}

#tokens-page .form-box .input-field input {
    background-color: rgba(36, 36, 48, 1);
    border-radius: 8px;
    border: 0px solid #585858;
    color: #fff;
    width: 100%;
    height: 50px;
    padding: 0 15px;
}

#tokens-page .form-box .input-field input::placeholder {
    color: #737373;
}


#tokens-page .form-box button {
    color: #fff;
    background: rgba(204, 94, 255, 1);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 15px 40px;
    font-size: 16px;
    width: 50%;
    margin: 0 auto;
    margin-left: 25%;

}

.toknenicon {
    font-weight: 600;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid #fff;
    text-align: center;
    line-height: 46px;
    margin-right: 15px;
    background: linear-gradient(140deg, #c600ff, #0c2c6c 50%, #485050 75%);
    color: #fff;
    overflow: hidden;
}

.modal {

    background: rgba(0, 0, 0, 0.5);
}

.deletebtn {
    padding: 10px;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;

}

.deletebtn span {
    opacity: 0;
    position: absolute;
    z-index: 99;
    top: 4px;
    font-size: 12px;
    right: 40px;
    padding: 10px;
    background-color: #000;
    color: #fff;
    white-space: nowrap;
    font-weight: 500;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-radius: 8px;
}

.deletebtn:hover span {
    opacity: 1;
}

.deletebtn span::before {
    content: "";
    border-width: 10px;
    border-style: solid;
    position: absolute;
    right: -20px;
    bottom: 18%;
    rotate: 90deg;
}

.deletebtn span::before {
    border-color: transparent transparent black transparent;
}

@media(max-width:767px) {

    /* .toknenicon{
        display: none;
    } */
    #tokens-page .lefty-flex h6,
    .righty h6 {
        font-size: 14px;
    }
}

@media(max-width:500px) {
    #tokens-page .lefty-flex {
        position: relative;
    }

    .toknenicon {
        position: absolute;
        top: -45px;
        left: -10px;
        width: 36px;
        height: 36px;
        line-height: 36px;
    }

    #tokens-page .token-box {
        margin-bottom: 30px;
    }
}