.PageHeader {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    justify-content: space-between!important;
    align-items: center;
}

.PageHeader__title {
    font-size: 16px;
    font-weight: 400;
    color: white;
   
}

.PageHeader--is-sticky {
    top: 0;
    position: relative;
    position: sticky;
    justify-content: center;

}
