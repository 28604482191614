@font-face {
  font-family: 'helveticaneuemedium';
  src: url('./fonts/helveticaneuemed-webfont.woff2') format('woff2'),
    url('./fonts/helveticaneuemed-webfont.woff') format('woff'),
    url('./fonts/HelveticaNeue.ttf') format('ttf'),
    url('./fonts/HelveticaNeueMedium.otf') format('otf');
}

@font-face {
  font-family: 'helveticaneuethin';
  src: url('./fonts/helveticaneue-thin-webfont.woff2') format('woff2'),
    url('./fonts/helveticaneue-thin-webfont.woff') format('woff'),
    url('./fonts/HelveticaNeueThin.otf') format('otf'),
    url('./fonts/HelveticaNeue-Thin.ttf') format('tff');

}

body {
  margin: 0;
  padding: 0;
  /* font-family: "helveticaneuemedium" !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  overflow: auto;
  font-family: 'Work Sans', sans-serif!important;
  
}

.dsds
{
  width: 100%;
  text-align: center;
  color: #fff;
  display: BLOCK;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #fff
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea,
select {
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

#root {
  padding: 0px;
  
  background-image: url('../src/images/Group 80.png');
  background-color: #111117;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 0;
  background-position: left top;
}

.dashb .chart-img img{
  width: 100%;
  margin-top: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}


.RoundedInput {
  width: 100%;
    background-color: rgba(36, 36, 48, 1);
    /* height: 45px; */
    color: #fff;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 20px;
    box-sizing: border-box;
    resize: none;
    border: 1px solid #222121;
    padding: 15px 20px;
}
.FormColumn textarea::placeholder{
  color: #505050;
  font-weight: 400;
}
.form-control:focus {
  background-color: rgba(36, 36, 48, 1);
  color: #fff;
  border: 1px solid rgba(204, 94, 255, 0.6)!important;
  box-shadow: none;
}

.DeployContractContent textarea{
  height: 200px!important;
}

.RoundedInput:last-of-type {
  margin-bottom: 2px;
}

.RoundedInput::placeholder {
  color: #fff;
}

.RoundedInput:-ms-input-placeholder {
  color: #92a2d6;
}

.RoundedInput::-ms-input-placeholder {
  color: #92a2d6;
}

.InputError {
  font-size: 14px;
    font-weight: 400;
    text-align: center;
    /* line-height: 20px; */
    color: #ff1d25;
}
.ContractPage__detail-view .TabBar .TabBarItem .TabBarItem__icon
{
  display: none;
}
.FormSectionTitle {
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-left: 15px;
}

.FormColumns {
  display: flex;
  flex-direction: row;
  margin-left: -6px;
  margin-right: -6px;
  justify-content: center;
  margin-top: 25px;
}

.FormColumn {
  display: flex;
  flex-direction: column;
  flex: 50%;
  margin-left: 6px;
  margin-right: 6px;
}

a:hover {
  color: rgba(204, 94, 255, 1)!important;
  text-decoration: none !important;
}
.btn1,.btn124,.btn2,.GradientButton,.sendw,.recieveW,.GhostButton
{
  display: inline-block;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.btn1:hover,.btn124:hover,.btn2:hover,.GradientButton:hover,.sendw:hover,.recieveW:hover,.GhostButton:hover
{
  transform: scale(0.95);
}

.GradientButton:hover
{
  color: #fff!important;
}
.dashboard {
  color: white;
}


.col-lg-2.side-nav.for-wi3 {
  flex: 0 0 16%;
  max-width: 16%;
}

.main-wrapper
{
  margin-bottom: 30px;
}
.main-wrapper .a1
{
width: 72%;
}
.main-wrapper .a2
{width: 28%;

}
.col-lg-10.fkju7 {
  flex: 0 0 84%;
  max-width: 84%;
}

.sidecontainer .trans-box .heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:20px 0;
}
.sidecontainer .trans-box .heading h4{
  color: #fff;
  font-size: 16px;
}
.sidecontainer .trans-box .heading img{
  width: 20px;
}
.sidecontainer .trans-box .heading a{
  background-color: transparent;
    padding: 0;
    display: block;
    width: 0;
    margin: 0 25px;
}
.sidecontainer .trans-box .trans-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.sidecontainer .trans-box .trans-flex .lefty h5{
  font-size: 15px;
  margin: 0;
}
.sidecontainer .trans-box .trans-flex .lefty span{
  font-size: 12px;
  color: #787A8D;
  font-weight: 500;
}
.sidecontainer .trans-box .trans-flex .righty h5{
  font-size: 15px;
  margin: 0;
  word-break: break-all;
  
}
.sidecontainer .trans-box .trans-flex .righty span{
  font-size: 12px;
  color: #787A8D;
  font-weight: 500;
  text-align: end;
  display: block;
}
.detail {
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 40px;
  list-style: none;
  background: #ffffff1c 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 6px 6px #ffffff45;
  border-radius: 49px;
  opacity: 1;
 
  margin-top: 10px;
  text-align: center;
  background: #ffffff1c 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 6px 6px #ffffff45;
  border-radius: 49px;
  opacity: 1;
 
}

.detail span {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.effectClass p {
  font-size: 18px;
  margin-top: 5px;
}

.effectClass img {
  width: 15%;
  height: 40px;
}

.detail .img:hover {
  filter: brightness(150%);
  box-shadow: 0px 0px 7px 0px rgb(255 255 255 / 50%);
}

.main-wrapper .dash-flex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}


.right-side-details {
  padding: 5px;
  width: calc(100% / 3);
  position: relative;
}

.right-side-details p {
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 14px;
}


.right-side-details details {
  color: #01F2E9;
}
.right-side-details img{
  margin-right: 15px;
  width: 40px;
  cursor: pointer;
}
.right-side-details img.imgrefeshinc{
  position: absolute;
  right: 0;
  width: 20px;
}
.custom-flex{
  font-size: 16px;
  background: rgb(21, 22, 29);
  box-shadow: none;
  border-radius: 8px;
  opacity: 1;
  padding: 15px;
  height: 90px;
  border: 1px solid #222121;
  position: relative;
  display: flex;
  align-items: center;
}

.heading h2{
  margin-bottom: 20px;
}

/* demo */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #31b0dd;
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}

::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: #893dfc !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: #893dfc !important;
  background-color: #893dfc !important;
}

.unlock-nav>li>a {
  text-align: center;
  cursor: pointer;
  color: white !important;
  display: block;
  transition: 0.3s ease-in-out all;
  padding: 12px;
  background: rgba(36, 36, 48, 1);
}
.unlock-nav>li>a p{
  color: #D2D2D2;
  margin: 0;
}
.unlock-nav>li>a.active {
  background: rgba(80, 184, 223, 1);
  color: rgba(0, 0, 0, 1) !important;
}
.unlock-nav>li>a.active p{
  color: rgba(0, 0, 0, 1) !important;
  /* font-weight: 500; */
}

li.Privatekey2nd {
  position: relative;
}

.scd-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
}

.active .scd-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  display: block;
}

.side-bar-text {
  border-radius: 20px;
  margin: auto;
  background: transparent linear-gradient(180deg, #be93fffe 0%, #893dfc 100%) 0% 0% no-repeat padding-box;

  width: 78%;
  color: white;
}

.side-nav {
  border-radius: 0;
}

.right-side-details span {
  /* word-break: break-all; */
  font-size:18px;
  font-weight: 600;
  display: block;
}


.right-side-details span title {
  justify-self: end;
}

.transaction-table {
  width: 100% !important;
}

.ReceiveModal {
  width: 100%;
  min-height: 100%;
}

.ReceiveModal__public-address {
  word-break: break-all;
}

.ReactModal__Content .Modal {
  width: 100%;
  padding: 10px;
  position: relative;
}

.ReactModal__Content .Modal>div {
  border-radius: 8px;
}



.custom-btn {
  border-radius: 10px;
  margin-top: -6px;
  float: right;
  z-index: 1;
  padding: 0;
  transition: 0.3s all ease-in-out;
}

.refreshbutton {
  float: right;

}

/* .custom-btn:hover {
  transform: scale(1.2) rotate(180deg) !important;
} */

.btn:hover {
  transform: scale(1.2);
}

.remove-space {
  white-space: pre !important;
  text-transform: capitalize;
}

.trans-his{
  margin-top: 30px;
}


@media only screen and (max-width:991px) {
 
  .custom-btn {
    float: none;
    margin-top: 0;
    top: 5px!important;
    right: 15px!important;
  }
 
  .NavBar__logo{
    height: 35px;
  }
 
}

input::placeholder{
  font-size: 16px!important;
}
input{
  height: 50px!important;
}
textarea{
  height: 50px!important;
}
textarea::placeholder{
  font-size: 16px!important;
}
.unlock-wallet .unlock-img{
    background: linear-gradient(0deg, #310057 0%, rgba(1, 1, 1, 0.00) 100%);
    height: 615px;
    border-radius: 0px 0px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

iframe {
  display: none !important;
}

header .header{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 75px;
    margin-top: 10px;
    margin-bottom: 20px;
}
header .header .logo img{
  width: 160px;
}
header .header .navs > ul{
  margin: 0;
  padding: 0;
}
header .header .navs > ul > li {
  position: relative;
  display: inline-block;
}
header .header .navs > ul > li > a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  padding: 25px 20px;
  margin: 0 3px;
  cursor: pointer;
}
header .header .navs > ul > li:hover > a {
  color: #fff;
}
header .header .btns-box{
  display: flex;
  align-items: center;
}
.btns-box a.btn1{
    border: 1px solid rgba(204, 94, 255, 1);
    background-color: transparent;
    color: rgba(204, 94, 255, 1);
    font-weight: 400;
    border-radius: 100px;
    padding: 12px 20px;
    margin-right: 20px;
}
.btns-box a.btn2{
  background: linear-gradient(268.92deg, #246CF9 -41.05%, #CF68FF -41.03%, #3EC3DA 167.68%);

    border-radius: 100px;
    outline: none;
    border: none;
    padding: 13px 20px;
    font-weight: 400;
    color: rgba(21, 22, 29, 1)!important;
}

@media (min-width: 320px) and (max-width: 991px){

  

  .unlock-wallet .unlock-img img{
    display: none!important;
  }
  .wallet-page .unlock-img img{
    display: none!important;
  }
  .CreateWalletPage__wrapper{
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .ChoosePasswordCard__subtitle{
    font-size: 11px!important;
  }
  .CreateWalletPage__subtitle{
    font-size: 12px!important;
  }
  .ChoosePasswordCard__footer{
    margin-top: 0!important;
  }
  .InteractWithContractContent a.GradientButton{
    padding: 12px 30px!important;
  }
 
  .ReceiveModal__public-address p {
    width: max-content;
    margin-bottom: 0;
    font-size: 12px!important;
    color: #FFA4FB;
    margin-top: 10px;
}
.ReceiveModal__public-address{
  display: block!important;
}
.ReceiveModal .ReceiveModal__buttons{
  padding: 0!important;
  margin-top: 10px!important;
}
.StakePurposeSelectorItem__subtitle {
  font-size: 10px;
}
.StakePurposeSelectorItem__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #8C8C8C;
  text-decoration: none;
  cursor: inherit;
}
.imgClass {
  top: 25% !important;
  width: 15px!important;
}

  .SendModal__title img{
    width: 40px!important;
  }
 

  .trans-flex h5{
    font-size: 14px;
  }
  .navbar .navbar-brand img {
    width: 90px!important;
}
.navbar-toggler-icon{
  width: 20px!important;
}
.mobile-menu .navbar-toggler-icon{
  width: 20px!important;
  position: absolute;
    right: 25px;
    top: -60px;
}
.mobile-menu .dropdown-menu {
  right: 22px;
  left: auto;
  background: transparent linear-gradient(180deg, #1d252d 0%, #4d5d6d 100%) 0% 0% no-repeat padding-box;
  width: auto;
  top: 20px;
}

.dropdown img {
  margin-left: 15px;
  width: 20px;
}
.unlock-wallet .unlock-img img{
  display: none;
}

.navbar-light .navbar-nav .nav-link{
  font-size: 14px!important;
}
.btns-box{
  margin-top: 10px;
  width: 100%;
}
.navbar-toggler:focus{
  box-shadow: none!important;
}


  .NavBar__logo{
    width: 110px;
  }
  body {
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh
  }
  

}
@media (min-width: 320px) and (max-width: 991px){
  .UnlockWalletViaMnemonicPhrase .input-flex{
    margin-top: 10px;
  }

  
  .explorer-img a img{
    width: 100%;
  }
  .nav-link {
    padding: 0 0px!important;
}
.main-wrapper .dash-flex{
  flex-wrap: nowrap!important;
  margin-top: 0px;
  display: block;
  overflow: auto;
  /* white-space:s nowrap; */
}

 

.right-side-details .custom-flex
{
  display: block;
  text-align: center;
}
.networktype p {
  margin: 0;
  font-size: 12px!important;
  font-weight: 500;
  display: flex;
}
.networktype p span a {

  font-size: 12px!important;
  margin-left: 5px;
}

.StakesTable tr td a{
  width: 100px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.unlock-wallet .unlock-img{
  display: none;
}
.navbar-collapse{
  background-color: #000!important;
  padding: 10px;
}
.main-wrapper .a2
{
  padding-right: calc(var(--bs-gutter-x)* .5)!important;
}
.wrapper-transacton
{
  
  margin: 0px!important;
  /* justify-co?ntent: space-between; */
  display: block!important;
  text-align: left !important;
}
.tranasctiontype {
  
  float: left!important;
  margin-bottom: 10px!important;
}

.class-compketealwfedws p {
  font-size: 18px;
  font-weight: normal;
  text-align: start;
  line-height: 20px;
  color: #fff;
  width: 100%;
}

.class-compketealwfedws h3{
  width: 100%;
}
.WalletApp{
  height: auto!important;
  padding-bottom: 60px;
}
.SendModal__title img{
  height: auto!important;
}
.ReceiveModal__title img{
  height: auto!important;
}
.transaction-table {
  margin: 0;
}
.trans-his {
    margin: 0;
}
.ExportKeystoreContent a.GradientButton{
  width: 100%!important;
}
.WalletPage__master-view{
  width: 100%!important;
}
.StakePurposeContainer__footer {
  width: 100%!important;
  margin: 0 auto!important;
  /* margin-top: 60px; */
}
.DeployContractContent a.GradientButton{
  width: 100%;
}
}

@media (min-width: 1025px) and (max-width: 1200px){
  .explorer-img a img{
    width: 100%;
  }
  
.TabBarItem__title{
  font-size: 14px!important;
}


}

.ExportKeystoreContent input{
  height: 60px;
}
.ExportKeystoreContent input::placeholder{
  color: #505050!important;
  font-weight: 500;
  font-size: 14px;
}
.pageHeading{
  text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 50px;
    color: #fff;
}
.undefined{
  justify-content: start!important;
}