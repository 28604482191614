.UnlockWalletPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

}
.wallet-page .create-box{
    border-radius: 30px;
    background: linear-gradient(135deg, rgb(144,79,120) 0%, rgb(81,126,138) 100%);
    -webkit-backdrop-filter: blur(129px);
    backdrop-filter: blur(129px);
    box-shadow: inset -1px 0px 12px #ffffff63, 0px 3px 6px #00000029;
    padding: 30px;
}

.unlock-wallet{
    padding: 20px 100px;
    width: 100%;
    height: auto;
    
}

.UnlockWalletPage__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.UnlockWalletPage__title {
    font-size: 36px;
    margin-top: 20px;
    text-align: center;
    background: linear-gradient(76.3deg, #A02DE6 3.99%, #DD8282 90.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.UnlockWalletPage__title p{
    font-weight: 300;
    font-size: 18px;
}
.UnlockWalletPage__title img {
    display: none;
}

.UnlockWalletPage__subtitle {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
    width: 100%;
    color: rgba(254, 254, 254, 1);
}


.UnlockWalletPage__subtitle a {
    margin-left: 6px;
    color: #FF72F9;
}

.UnlockWallet {
    width: 100%;
}

.UnlockWalletCard__content {
    border-radius: 30px;
    background: rgba(21, 22, 29, 1);
    padding: 30px 60px 50px;
    margin: 10px 0;
    border: 1px solid rgba(255, 255, 255, 0.08);
}

.UnlockWalletCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

.UnlockWalletCard__header ul li:active {
    transition: all 0.1s ease-out;
}

.UnlockWalletCard__footer {
    margin-top: auto;
}

.UnlockWalletCard__title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 26px;
    color: white;
    text-transform: uppercase;
}

.UnlockWalletCard__subtitle {
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
    color: #92a2d6;
}

.UnlockWalletCard__tab-bar {
    width: calc(100% + 34px);
    background-color: transparent;
}

.UnlockWalletCard__warning {
    position: relative;
    font-size: 13px;
    color: rgba(250, 89, 89, 1);
    padding-left: 20px;
    }
.UnlockWalletCard__warning::before{
    content: '\f05a';
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    left: 0;
    font-size: 14px;
    color: rgba(250, 89, 89, 1);
}


.UnlockWalletCard__header .unlock-nav {
    width: 100%;
    margin: 0 auto;
}

.unlock-nav li {
  
    position: relative;
    width: calc(100% / 3);
}

.UnlockWalletViaKeystoreFile__footer.mt-5 {
    margin-top: 15px !important;
}

.UnlockWalletViaKeystoreFile__footer {
    margin-top: auto;
    margin-bottom: 0px;
}

.UnlockWalletViaKeystoreFile__file-input {
    outline: none !important;
    padding: 12px;
    font-size: 16px;
    /* height: 50px !important; */
    resize: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    /* text-align: start; */
    letter-spacing: 0px;
    color: rgba(226, 218, 240, 1);
    border: 1px dashed #B5B5B5;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
    background: rgba(36, 36, 48, 1);
}

.UnlockWalletViaKeystoreFile__file-input--has-file::after {
    content: "";
    display: block;
    position: absolute;
    height: 20px;
    width: 20px;
    right: 12px;
    background-image: url("/img/icons/checkmark-green@2x.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    top: 12px;
    filter: invert(1);
}


.UnlockWalletViaKeystoreFile__file-input input[type="file"] {
    display: none;
}

.UnlockWalletViaKeystoreFile__title {
    font-size: 16px;
    font-weight: 300;
    color: rgba(226, 218, 240, 1);
    margin-bottom: 10px;
    margin-left: 15px;
}

.UnlockWalletViaKeystoreFile__password-input,
.UnlockColdWalletLedger__select {
    outline: none !important;
    border: none !important;
    padding: 20px 12px;
    font-size: 14px;
    height: 50px !important;
    resize: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    color: #fff;
    background-color: rgba(36, 36, 48, 1);
}

.UnlockColdWalletLedger__select {
    width: 100%;
    margin-bottom: 18px;
    text-align: center;
}

.UnlockWalletViaMnemonicPhrase__title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(226, 218, 240, 1);
    margin-bottom: 5px;
    margin-left: 15px;
}

.UnlockWalletViaMnemonicPhrase__mnemonic {
    outline: none !important;
    border: none !important;
    padding: 12px;
    font-size: 16px;
    resize: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: rgba(226, 218, 240, 1);
    min-height: 80px !important;
    background-color: rgba(36, 36, 48, 1);
}

.UnlockWalletViaMnemonicPhrase__mnemonic-instructions {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 20px;
    color: #FFFFFF;
    margin-left: 15px;
    width: 100%;

}

.UnlockWalletViaMnemonicPhrase__password-input {
    outline: none !important;
    border: none !important;
    padding: 20px 12px;
    font-size: 14px;
    resize: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    letter-spacing: 0px;
    color: #fff;
    background-color: rgba(36, 36, 48, 1);
}

.UnlockWalletViaPrivateKey {
    /* display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    padding: 50px 160px;
    box-shadow: inset 0px 6px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;
   
    -webkit-backdrop-filter: blur(30px);
    width: 70%;
    margin: 0 auto; */
}

.UnlockWalletViaPrivateKey__footer {
    margin-top: auto;
    position: relative;
    margin-bottom: 0px;
    border-radius: 7px;
    margin-top: 10px !important;
}


.UnlockWallets:hover {
    color: green;
}

.UnlockWalletViaPrivateKey__title {
    font-size: 16px;
    font-weight: 400;
    /* line-height: 22px; */
    color: rgba(226, 218, 240, 1);
    margin-bottom: 10px;
    margin-left: 15px;
}

.UnlockWalletViaPrivateKey__private-key {
    outline: none !important;
    border: none !important;
    padding: 12px;
    font-size: 16px;
    height: 50px !important;
    resize: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    text-align: start;
    /* letter-spacing: 0px; */
    color: rgba(226, 218, 240, 1);
    background-color: rgba(36, 36, 48, 1);
}


.UnlockWalletViaPrivateKey__private-key-instructions {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 1);
    width: 100%;
  
}

.UnlockWalletViaPrivateKey__password-input {
    outline: none !important;
    border: none !important;
    padding: 12px;
    font-size: 16px;
    height: 50px !important;
    background-color: rgba(36, 36, 48, 1);;
    resize: none;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    text-align: start;
    color: rgba(226, 218, 240, 1);;
}
.UnlockWalletViaPrivateKey__password-input::placeholder{
    font-size: 12px;
}

.UnlockWalletViaColdWallet {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 360px;
    margin: 20px;
}

.UnlockWalletViaColdWallet__footer {
    margin-top: auto;
}

.UnlockWalletViaColdWallet__title {
    font-size: 19px;
    font-weight: 300;
    line-height: 22px;
    color: white;
    margin-bottom: 0;
}

.UnlockWalletCard__header img {
    height: 50px;
    /* width: 50px; */
}

.landing {
    display: block;
    margin: 50px auto;
}

.unlock-wallet .unlock-img img{
    width: 90%;
    margin: 0 auto;
    display: block;
}

@media only screen and (max-width: 1300px) {
    .UnlockWalletViaPrivateKey {
        padding: 0px;
        width: 100%;
    }
    .unlock-wallet {
        padding: 0 10px;
    }
}

@media (min-width: 991px) and (max-width: 1200px) {

    .UnlockWalletViaMnemonicPhrase,
    .UnlockWalletViaKeystoreFile {
        padding: 0px;
        width: 100%;

    }
    .UnlockWalletViaMnemonicPhrase__mnemonic{
        font-size: 14px;
        height: 50px !important;
    }
    .UnlockWalletViaPrivateKey__private-key{
        font-size: 14px;
        height: 50px !important;
    }
}

@media only screen and (max-width : 991px) {
    .UnlockWalletViaKeystoreFile {

        padding: 0px;

    }
   
    .UnlockWalletCard__header img {
        height: 30px;
    }

    .dsda5dwd {
        padding: 0
    }

    .UnlockWalletPage__title img {
        display: none;
    }

    .MnemonicCard__title {
        font-size: 16px;
    }

    .ReactModal__Content .Modal {
       
        margin: 0 auto;
    }

 

    .PrivateKeyModal {
        padding: 15px 20px;
    }

    .UnlockWalletViaKeystoreFile__password-input,
    .UnlockColdWalletLedger__select {
        /* background-color: white; */
        height: 50px;
        color: white;
        border: none;
        outline: none;
    }


    .UnlockWalletViaPrivateKey {
        padding: 0px
    }

    .UnlockWalletViaMnemonicPhrase {
        padding:0px
    }

    .UnlockWalletPage__subtitle {
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .unlock-wallet .unlock-img img {
        width: 100%;
        margin-bottom: 40px;
    }
    .UnlockWalletCard__content {
        padding: 10px;
        margin-top: 20px;
    }

    .UnlockWalletViaKeystoreFile__footer,
    .UnlockWalletViaMnemonicPhrase__footer,
    .UnlockWalletViaPrivateKey__footer {
        margin-bottom: 0;
        width: 100%;
    }


}

@media only screen and (max-width: 991px) {
    .UnlockWalletViaKeystoreFile {
        width: 100%;
    }
   
    .cusomtmobile-row
    {
        margin: 0;
    }
   
    .UnlockWalletPage__title {
        font-size: 22px;

    }
   
    

    .PrivateKeyModal {
        padding: 15px 10px!important;
    }

    
   
    .UnlockWalletViaMnemonicPhrase , .UnlockWalletViaPrivateKey{
        width: 100%;
    }


    .UnlockWalletViaMnemonicPhrase__mnemonic,
    .UnlockWalletViaMnemonicPhrase__password-input,
    .UnlockWalletViaKeystoreFile__file-input,
    .UnlockWalletViaKeystoreFile__password-input,
    .UnlockWalletViaPrivateKey__private-key,
    .UnlockWalletViaPrivateKey__password-input,
    .ChoosePasswordCard__password-input {
        padding: 15px 12px;
        font-size: 14px;
        height: 50px !important;
        width: 100%;
    }
    .UnlockWalletViaMnemonicPhrase__mnemonic{
        height: 45px!important;
        font-size: 12px;
    }

    .UnlockWalletViaMnemonicPhrase__title ,.selectSertivationoath{
        font-size: 14px;
        margin-bottom: 0px;
    }

    .UnlockWalletViaMnemonicPhrase__mnemonic-instructions,
    .UnlockWalletCard__warning,
    .UnlockWalletViaPrivateKey__private-key-instructions {
        font-size: 12px;
    }

    .dd-button {
        font-size: 16px;
    }

    .mnemonic,
    .keystore,
    .PrivateKey,
    .MnemonicWarningCard__warning-body,
    .MnemonicCard__view-private-key,
    .MnemonicCard__phrase-container p,.ChoosePasswordCard__error {
        font-size: 12px;
    }

    .MnemonicCard__instructions {
        font-size: 14px;
    }

    .ChoosePasswordCard__title {
        font-size: 16px;
    }

    .ChoosePasswordCard__header
    {
        margin-bottom: 15px;
    }
    label.lab {
        font-size: 14px;
    }
    .ChoosePasswordCard__error
{
    font-weight: 300;
}
    .ChoosePasswordCard__message span,.text-danger
    {
        font-size: 10px;
    }

    .MnemonicWarningCard,
    .MnemonicCard {
        padding: 0;
        margin-top: 0;
    }
    .MnemonicWarningCard__footer
    {
        margin-top: 15px;
    }

    .MnemonicWarningCard__subtitle,
    .MnemonicWarningCard__warning-title {
        font-size: 14px;
    }

    .MnemonicWarningCard__warning-body
   {
        width: 100%;
    }


    .CreateWalletPage__title {
        font-size: 24px;
    }

    .MnemonicCard__content {
        padding-bottom: 0px;
    }

    .MnemonicCard__phrase-container {
        padding: 0 30px;
        font-size: 10px;
    }
    .PrivateKeyModal {
        padding: 15px 15px;
    }

  
    .WalletCreationCompleteCard {
        padding: 15px 10px;
    }
    .unlock-wallet {
        padding: 0;
    }
}