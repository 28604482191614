.TabBarItem {
    display: block;
    text-align: left;
    padding: 20px 15px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}

.logoutcontainer {
    float: right;
    padding: 15px;
}

.TabBarItem__icon-active
{
    display: none;
}



.TabBarItem--is-active1 {
    background: #2d2d34;
    border-color: rgba(100, 24, 195, 1) !important;
}

.TabBarItem__icon,
.TabBarItem__icon-active {
    width: 20px;
    padding-left: 0px;
    padding-right: 0px;

}

.TabBarItem .TabBarItem__icon {
    width: 20px;
}


.TabBarItem__title {
    font-size: 16px;
    margin-top: 0px;
    color: rgba(191, 191, 191, 1);
    display: inline;
    margin-left: 10px;
    word-break: break-word;
    white-space: break-spaces;
    font-weight: 400;
}

.PageHeader .TabBarItem--is-active {
    border-radius: 6px;
    padding: 14px;
    text-transform: capitalize;
}


.TabBarItem--is-active .TabBarItem__icon-active {
    display: inline-block;
}

.TabBarItem--is-active .TabBarItem__icon {
    display: none;
    position: relative;
}

.tab-wrapper-send-recieve a .TabBarItem__icon {
    width: auto;
    height: 26px;
    padding-right: 0px;
    display: none;
}
@media (min-width: 1024px) and (max-width: 1600px) { 
    .snd-rec .tab-wrapper-send-recieve a .TabBarItem__icon {
        width: auto;
        height: 20px;
        padding-right: 0px;
    }
    .snd-rec .TabBarItem__title{
        font-size: 14px;
    }
}
@media(max-width: 1100px) {
    .TabBarItem__title {
        font-size: 14px;
    }
}
