

#tokens-page .tok-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

#tokens-page .tok-flex img{
    cursor: pointer;
}

#tokens-page .lefty-flex{
    display: flex;
    justify-content: start;
    align-items: center;
}
#tokens-page .lefty-flex img{
    width: 20px;
    margin-right: 10px;
}
#tokens-page .lefty-flex h6{
    margin: 0;
    /* font-size: 16px; */
    padding-bottom: 5px;
}
#tokens-page .lefty-flex h6 span{
    color: #9F9F9F;
    font-size: 14px;
}
#tokens-page .lefty-flex p{
    margin: 0;
    /* color: #9F9F9F; */
    font-size: 14px;
}
#tokens-page .lefty-flex p b{
    color: #93FFBE;
}

#tokens-page .righty h4{
    margin: 0;
    color: #CDCDCD;
    font-size: 16px;
}
#tokens-page .righty h4 b{
    color: #93FFBE;
}

#tokens-page .modal-content{
    background: rgba(21, 22, 29, 0.87);
    border-radius: 8px;
}
#tokens-page .modal-content i{
    color: #fff;
    text-align: end;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}
#tokens-page .modal-content .modal-title{
    text-align: center;
}


#tokens-page .form-box {
    padding: 20px;
}
#tokens-page .form-box .input-field{
    margin-bottom: 20px;
}

