.ContractPage {
    display: flex;
    flex-direction: row;
    flex: 1;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #ffffff45;
    border-radius: 49px;
    opacity: 1;
    -webkit-backdrop-filter: blur(30px);

}

.ContractPage__detail-view {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    position: relative;
    margin-top: 30px;

    /* height: 445px; */
}

.sisbn .FormColumn {
    margin-top: 15px;
}

.InteractWithContractContent .sisbn textarea {
    height: auto !important;
}

.ContractPage__detail-view .TabBarItem--is-active1 {
    border-radius: 8px;
    background: rgba(116, 111, 227, 1);
}

.InteractWithContractContent
{
    margin-top: 30px;
}
.ContractPage__detail-view .TabBar--is-centered .TabBarItem {
    margin: 0;
}

.ContractPage__detail-view .TabBarItem {
    padding: 10px 15px;
    border-radius: 8px;
}
.ContractPage__detail-view .TabBarItem .TabBarItem__title{
    font-size: 14px;
    margin-left: 0px;
    font-weight: 400;
}

.past4r {
    position: relative;
}

.NavBar__wallet-copy-address-icon1 img {
    width: 24px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.NavBar__wallet-copy-address-icon1 img:hover {
    transform: scale(1.0);
}

.past4r span {
    color: #aeadad;
    position: absolute;
    right: 9px;
    top: 9px;
}

.RoundedInput.form-control.pag5 {
    padding-right: 60px;
}

.DeployContractContent,
.InteractWithContractContent {
    padding: 0px 6px;
}

.InteractWithContractContent .FormSectionTitle {
    text-align: left;
    
}

.InteractWithContractContent .GradientButton {
   margin-top: 30px;
}




.ContractPage textarea {
    height: 174px;
    padding-top: 10px;
    margin-bottom: 10px !important;
}

.ContractPage input {
    margin-bottom: 20px !important;
    border-radius: 4px;
}

.ContractPage select {
    margin-bottom: 20px !important;
}

.ContractPage form .InputError {

    margin-bottom: 20px;
}
