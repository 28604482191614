.NavBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent linear-gradient(180deg, #2f3944 0%, #414d5a 100%) 0% 0% no-repeat padding-box;
  flex-shrink: 0;
}

.NavBar1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #1f0a4d;
  height: 100px;
  flex-shrink: 0;
}

.dropdown-item:focus, .dropdown-item:hover
{
  background: none;
  color: #000!important;
  /* background-image: linear-gradient(140deg, #C600FF 0%, #CFDFFF 50%, #21FCFC 75%); */
  /* border-radius: 30px; */
  /* padding: 10px 20px; */
}
.NavBar__logo {
  height: 50px;
  margin-top: -20px;
  align-self: center;
}

.NavBar--is-centered {
  justify-content: space-between;
  padding-top: 20px;
}

.NavBar--is-end {
  justify-content: end;
}

.NavBar__account {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.NavBar__wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NavBar__wallet-title {
  font-size: 14px;
  font-weight: normal;
  color: #8a8fb5;
  margin-right: 6px;
}

.NavBar__wallet-address {
  font-size: 14px;
  font-weight: normal;
  color: white;
}

.NavBar__wallet-copy-address-icon {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}


.NavBar__wallet-copy-address-icon:hover {
  transform: scale(1.2);
}
.navbar .navbar-brand
{
  position: relative;
}

 .navbar-brand img{
  width: 140px;
}
 .navbar-brand  span
{
  color: rgba(165, 172, 185, 1);
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: 20px;
    right: 0;
}
nav.navbar{
  background: rgba(21, 22, 29, 1);
  padding: 20px 50px;
  
}

.navbar ul li{
  padding:  0 30px;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(80, 184, 223, 1);
  font-size: 16px;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  color: #fff
}
.NavBar__logout {
  color: #8a8fb5;
  margin-top: 6px;
  cursor: pointer;
  transition: opacity, 0.2s, ease-in-out;
  position: relative;
  z-index: 99;
}
.networktype p{
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}
.networktype p span a{
  color: rgba(204, 94, 255, 1);
  font-size: 16px;
}

.imgClass {
  position: absolute;
  right: 10px;
  top: 13px !important;
}

.NavBar__network-badge {
  position: relative;
  padding: 0;
  padding-left: 11px;
  padding-right: 22px;
  line-height: 12px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  background: #29b3eb;
  background: linear-gradient(to right, #29b3eb 0%, #21edba 100%);
  background-size: 100%;
  color: #1c243b;
  padding-top: 3px;
  height: 18px;
  box-sizing: border-box;
}

.NavBar__network-badge:after {
  display: block;
  content: "";
  width: 9px;
  height: 5px;
  /* background: transparent url("/img/icons/dropdown-arrow@2x.png") no-repeat; */
  background-size: 9px 5px;
  position: absolute;
  right: 7px;
  top: 6px;
}

.NavBar__network-badge:hover {
  background: #21edba;
}

.NavBar__network-badge.NavBar__network-badge--is-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: #1c243b;
  padding-left: 11px;
  padding-right: 11px;
  color: #8a8fb5;
}

.NavBar__network-badge.NavBar__network-badge--is-disabled:after {
  display: none;
}

@media (max-width: 991px) {
  .NavBar .NavBar__account {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  nav.navbar {
    padding: 0;
}

  .NavBar__wallet {
    display: block;
  }

}

.NavBar__wallet-address {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  display: inline-block;
  position: relative;
  float: right;
  z-index: 1;
  bottom: 10px;
  border-radius: 17px;
}
.NavBar--is-centered .dropdown {
  text-align: center;
}
.networktype {
  color: #fff !important;
  justify-content: center;
  display: flex;
  transition: all 500ms ease;
}
.networktype:hover, .fauc .footercustoma a:hover{
  color: #B88BFE!important;
}
.ver-fac{
  text-align: end;
  z-index: 1;
}
.ver-fac div {
  display: inline-block;
}
.networktype.fauc {
  padding-left: 15px;
}
.fauc .footercustoma {
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
  padding-left: 4px;
}
.footercustoma a {
  color: #B88BFE;
}
.fauc .footercustoma a {
  color: #fff;
  transition: all 500ms ease;
}
.fauc .footercustoma a span{
  color: #B88BFE;
}
.networktype.dropdown 
{
  width: 100%;
  z-index: 99;
}
.dd-button {
  /* top: 0px;
  right: 0px;
  width: 10px;
  display: inline-block;
  border: none;
  border-radius: 10px;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  color: white;
  position: relative;
  background: transparent;
  box-shadow: none;
  border-radius: 49px;
  opacity: 1;
  font-size: 18px; */
}
.dropdown img{
  margin-left: 15px;
}
.custom-flex1{
  display: flex;
  align-items: center;
}

.dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

.dd-button:hover {
  color: white;
  font-weight: bold;
}

.dd-input {
  display: none;
}

.dd-menu {
  z-index: 1;
  /* height: 182px; */
  width: 144px;
  overflow-y: scroll;
  top: 25px !important;
  position: absolute;
  top: 100%;
  border: 0px solid #ccc;
  border-radius: 4px;
  padding: 8px 0;
  margin: 2px 0 0 0;
  list-style-type: none;
  color: white;
  left: 0 !important;
  left: auto !important;
  background: #000;
  border-radius: 8px;
  opacity: 1;
 
}

.dropdownimgclass {
  float: inline-end;
}

.dd-input+.dd-menu {
  display: none;
}

.dd-input:checked+.dd-menu {
  display: block;
}

.dd-menu li {
  padding: 5px 20px!important;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  color: white;
  background-color: rgba(124, 129, 163, 0.35);
  font-weight: 200;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.cursor-pointer {
  cursor: pointer;
}

.dd-button.dropdown-toggle.testnet-mainnet
{

    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    position: static;
    width: auto;
    text-transform: capitalize;
    font-size: 14px;
}
.hidden
{
  display: none!important;
}
.dd-button.dropdown-toggle.testnet-mainnet:hover{
  color: #D06EFF;
}

.testnet-mainnet.dd-menu
{
  height: auto;
  left: 0!important;
  width: auto;
  min-width: auto;
  background: transparent;

}
.testnet-mainnet.dd-menu li a
{
  margin: 0;
  padding: 0;
  color: #fff;
}
@media (max-width: 991px) {
  .dropdown {
    float: none;
  }
}

@media (max-width: 991px) {
 
  .ver-fac div {
    display: flex;
}
.NavBar--is-centered {
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 0px;

}
}
