.WithdrawStakeModal {
      display: flex;
    flex-direction: column;
    background: rgba(21, 22, 29, 0.87);
    opacity: 1;
    width: 800px;
    padding: 50px 20px;
    box-sizing: border-box;
}

.WithdrawStakeModal__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: white;
   
}
.WithdrawStakeModal .modal-img {
    width: 30px;
    margin: 0 auto;
}

.WithdrawStakeModal__title img
{
    margin-right: 10px;
}