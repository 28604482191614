.StakesTable {
    color: white;
    border: none;
    border-collapse: collapse;
    width: 100%;
}

.StakesTable thead {
    border-bottom: 1px solid rgba(53, 58, 84, 1);

}

.NavBar__wallet-copy-address-icon {

    float: left;
    margin-left: 0;
    margin-top: -1px;
    margin-right: 5px;
}

.NavBar__wallet-copy-address-icon img {
    height: 20px;
}

.StakesTable th {

    white-space: nowrap;
    padding: 15px 10px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
}

.csev h5 {
    padding: 1em;
    font-weight: bold;
}

.csev {
    /* max-height: 600px; */
    overflow: auto;
    /* min-height: 370px; */
    /* padding-bottom: 60px; */
    background: rgba(21, 22, 29, 1);
    border-radius: 8px;
    border: 1px solid #222121
}

.StakesTable td {
    font-size: 14px;
    padding: 10px;
}

.StakesTable__header--return-height {
    width: 150px;
}

.StakesTable__header--amount {}

.StakesTable__header--holder {
    width: 450px;
}

.fr-time {
    display: flex;
    width: 100%;
    justify-content: center;
}

.fr-time p {
    margin-bottom: 0;
    margin-left: 5px;
}

@media(max-width: 991px) {
    .csev {
        padding-bottom: 0px;
    }


}