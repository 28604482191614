.SendModal {
    width: 52vw;
    min-height: 540px;
    padding: 20px;
    box-sizing: border-box;
    background: rgba(21, 22, 29, 0.87);
    border-radius: 8px!important;
    opacity: 1;
   
}

.SendModal__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: white;
    margin-bottom: 0px;
}
.SendModal__title img{
    padding-right: 12px;
    width: 40px;
    margin-bottom: 10px;
}
@media screen and (max-width: 991px) {
    .SendModal__title img {
        width: 65px;
        height: 65px;
        padding-right: 0;
    }
}