.StakesPage {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.StakesPage__detail-view {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    position: relative;
    border-radius: 5px;
}

.StakesPage__detail-view-spinner {
    align-self: center;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-shrink: 0;
}

.tbaleodhe {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    height: 100%;
    width: 100%;
    background: rgba(21, 22, 29, 1);
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid #222121;
    opacity: 1;
}

.sd {
    display: block;
    text-align: center;
    margin: 10px;

}

.metatron-note {
    color: red !important;
}