.App {
  display: flex;
  flex-direction: column;

}

.smallcontainer {
  display: block;
  background: rgba(21, 22, 29, 1);
  padding: 15px;
  height: 100%;
  border-bottom-left-radius: 8px;
}

.custom-copy span {
  display: block;
  word-break: break-all;

}

.m-0 {
  margin: 0;
}

.custom-copy {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 22px;
}

.custom-copy.des-des img {
  width: 14px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.custom-copy p {
  margin: 0;
  align-items: flex-start;
  font-size: 14px;
  display: flex;
  font-weight: 400;
}


.DeployContractContent input {
  height: 50px !important;
}

.ContractPage__detail-view .TabBar {
  background-color: rgba(62, 63, 71, 1);
  border-radius: 8px;
  height: auto;
}

.DeployContractContent textarea {
  border-radius: 8px;
  border: 1px solid #222121;
  background: rgba(21, 22, 29, 1);
}

.navbar-collapse.dropdown-menu {
  position: relative;
  background: transparent;
  border: none;
}

.parentcontainer {
  width: 100%;
  margin-top: 10px;
}

.smallcontainer TabBarItem {
  width: 23px;
}

.tab-wrapper-send-recieve {
  display: flex;
  border-bottom: 1px solid rgba(53, 58, 84, 1);
  padding-bottom: 25px;
}

.tab-wrapper-send-recieve a.TabBar {
  width: 158px;
  height: 26px;
}

.mob-dis {
  display: none !important;
  margin-top: 20px;
}

.tab-wrapper-send-recieve a.TabBarItem__icon {
  height: 31px;
}

.sidecontainer {
  margin-top: 10px;
}

.explorer-img a {
  background-color: transparent !important;
  width: 100% !important;
}


a.custom-links {
  background-color: transparent !important;
  padding: 0;
  color: #21fcfc;
}


a.sendw,
a.recieveW {
  background: rgba(126, 27, 172, 1);
  display: block;
  padding: 15px;
  color: #fff !important;
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
}

a.recieveW {
  background: rgba(204, 94, 255, 1);
  margin-left: 15px;

}

a.sendw img,
a.recieveW img {
  float: right;
}

.bg-mine {
  color: #ffff;
}

.bg-mine h5 {
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
}

.sd {
  display: block;
  text-align: center;
}

.dropdown-menu {
  right: -25px;
  left: auto;
  width: 22rem;
}

.dropdown-toggle::after {
  display: none;
}

.wrapper-transacton {
  align-items: center;
  position: relative;
  display: flex;
  margin: 25px 0px 10px;
  justify-content: space-between;
}

.ddnodtye
{
  display: flex;
  margin-bottom: 10px;
  margin-top: 45px;
}
.ddnodtye h6{
  display: flex;
  margin-right: 5px;
}
.ddnodtye svg{
  width: 15px;
  margin-right: 10px;
}
.wrapper-transacton img {
  cursor: pointer;
  width: 15px;
}

.tokness {
  font-size: 14px;
}

.righty b {
  font-weight: normal;
}

.tokness span {
  background-color: #21fcfc;
  border-radius: 10px;
  padding: 3px;
  display: inline-block;
  color: #000 !important;
  margin-right: 10px;
  font-size: 10px !important;
}

.tokness.ds34d span {
  display: inline;
  padding: 2px;
  font-size: 10px;

}

.conteonspa {
  line-height: normal;

}

.conteonspa a {
  display: block;
  margin-top: 1px;
}

.bg-mine.wrapper-transacton #Refresh {
  right: 0;
  top: -35px;
}

.bg-mine.wrapper-transacton #Refresh1 {
  right: 25px;
  top: 5px;
}

.wrapper-transacton #Refresh {
  right: 25px;
  top: 5px;
}

.bg-mine h6 {
  font-size: 16px;

}



.wrapper-transacton #Refresh,
.wrapper-transacton #Refresh1 {
  position: absolute;
  /* right: 0; */
  margin: 0;
  /* top: 0; */
  right: 25px;
  top: 5px;
}

.derivationaoth.BottomBorderInput {
  width: 100%;
  border-radius: 8px;
  background-color: rgba(36, 36, 48, 1);
  color: rgba(226, 218, 240, 1);
  padding: 14px;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  text-align: start;
  border: none;
  margin-left: auto;
  display: block;
  margin-top: 2px;
}

.derivationaothparent {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
}

.selectSertivationoath {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(226, 218, 240, 1);
  margin-bottom: 5px;
  margin-left: 15px;
}

.UnlockWalletViaMnemonicPhrase .input-flex {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.navbar-toggler-icon {
  width: 40px;
  height: 38px;
  background-size: 100%;
  background-image: url("images/menu_bar_icon.png") !important;
}

.tranasctiontype {
  display: block;
  background: rgba(62, 63, 71, 1);
  float: right;
  border-radius: 8px;
}


.tranasctiontype span {
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  width: 100px;
  font-weight: 400;
  cursor: pointer;
  background: rgba(62, 63, 71, 1);
  text-align: center;
}

.tranasctiontype span.active {
  background: rgba(116, 111, 227, 1);
}

.spinnerss {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  left: 0;
  background: #000000d0;
  top: 0;
  z-index: 9999;
  bottom: 0;
  display: block;
}

.spinnerss img {
  position: relative;
  top: 20%;
}


.imgrefeshinc {
  transition: 0.3s all ease-in-out;
  width: 15px;
  float: right;
  margin-right: 0px;
  margin-top: 10px;
}

.imgrefeshinc:hover {
  transform: scale(1.2) rotate(180deg) !important;
}

.imgrefeshinc1 {
  margin-right: 22px;
  width: 22px;
  margin-top: 7px;
  /* transition: 0.3s all ease-in-out; */
}


.text-danger {
  margin: 5px;
  color: #FF4848;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  margin-left: 15px;
}

.pending.fin23 {
  filter: hue-rotate(206deg) brightness(95%) contrast(80%)
}

.footercustoma {
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
  padding-left: 10px;
}

.viewonexplorer {
  font-size: 12px;
  color: #d06eff;
  position: absolute;
  bottom: -28px;
  left: 28px;
  z-index: 99;
  cursor: pointer;
}

.dotodd {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.WalletApp nav.navbar {
  display: none;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.WalletApp .navbar-brand {
  position: relative;
  padding: 15px 10px 20px;
  display: block;
  margin: 0;
}

.WalletApp .navbar-brand span {
  top: 30px;
  left: 100px;
}


@media(max-width: 1200px) {
  .right-side-details img {
    margin-right: 5px;
    width: 25px;
  }

  footer .container {
    max-width: 100% !important;
  }

  .main-wrapper .a1 {
    width: 69%;
  }

  .main-wrapper .a2 {
    width: 31%;
  }

  .custom-flex {
    padding: 5px;

  }

  .side-nav a.TabBarItem {
    border-width: 5px;
  }

  .TabBarItem__title {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 991px) {

  .main-wrapper .a1,
  .main-wrapper .a2 {
    width: 100%;
  }

  .imgrefeshinc:hover {
    transform: rotate(0deg) !important;
  }

  .TabBar1 {
    position: fixed;
    bottom: -3px;
    z-index: 9;
    height: 70px!important;
  }

  .TabBarItem {
    width: calc(100% / 7);
    text-align: center !important;
    display: inline-block !important;
  }

  .WalletApp .navbar-brand span {
    left: 80px !important;
    top: 27px !important;
  }

  .TabBar1 .navbar-brand {

    display: none;
  }

  .WalletApp nav.navbar {
    display: block !important;
  }

  .TabBarItem__title {
    display: none !important;
  }

  /* TabBarItem__title */

  .pageHeading {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 30px;

  }

  .WalletApp+#footer {
    display: none;
  }

  #tokens-page .tok-flex {
    margin-bottom: 5px;
  }

  .imgrefeshinc {
    margin-top: 0;
  }

  .right-side-details {
    font-size: 14px;
    display: inline-block;
    width: 50%;
  }

  .token-box-flex {
    display: block !important;
  }

  .Pages {
    margin: 0px 0px !important;

  }

  .mob-dis {
    display: block;
  }

  .des-des.custom-copy {
    display: none !important;
  }

  #tokens-page .token-box {
    width: 100% !important;
  }

  .nav-link {
    margin-left: auto;
  }

  .logo-txt {
    margin-bottom: 25px;
    text-align: center;
  }

  .explorer-img a img {
    width: 100%;
  }

  .ver-fac div {
    display: inline-block !important;
  }

  .bg-mine.wrapper-transacton #Refresh1 {
    right: 0px;
    top: 10px;
  }

  .stakepgaez .wrapper-transacton img {
    cursor: pointer;
    width: 15px;
    float: RIGHT;
    margin-top: -34px;
  }

  .UnlockWalletViaPrivateKey__title {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  .stakepgaez {
    margin-top: 15px;
  }

  .btns-box a.btn1,
  .btns-box a.btn2 {
    border: none !important;
    padding: 0 !important;
    background: none;
    color: rgba(204, 94, 255, 1) !important;
    margin-bottom: 10px;
    font-size: 14px;
  }


  .btn1,
  .btn124,
  .btn2,
  .GradientButton,
  .sendw,
  .recieveW,
  .GhostButton {
    width: 100% !important;
  }

  .WithdrawStakeModal,
  .DepositStakeModal {
    padding: 20px !important;
  }

  .ContractPage__detail-view .TabBarItem__title {
    display: block !important;
  }

  .ContractPage__detail-view .PageHeader__title {
    margin-bottom: 10px;
  }

  .DeployContractContent,
  .InteractWithContractContent {
    padding: 10px 6px;
  }

  .ContractPage__detail-view .TabBar--is-centered .TabBarItem {
    width: 50%;
  }

  .right-side-details.pl-0,
  .right-side-details.pr-0 {
    padding: 5px !important;
  }

  .ContractPage__detail-view .PageHeader {
    display: block !important;
  }

  .derivationaothparent {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet-id {
    padding: 20px;
    font-size: 14px;
  }

  .sidecontainer {
    padding: 10px;
  }



  .dropdown-menu {
    right: -40px;
    left: auto;
    background: transparent linear-gradient(180deg, #1d252d 0%, #4d5d6d 100%) 0% 0% no-repeat padding-box;
    width: auto;
    top: 28px;
  }

  .nav-link {
    padding: 0;
  }

  .navbar {
    left: 0;
    right: auto;
    z-index: 99;
  }



  .tab-wrapper-send-recieve {
    width: 100%;
  }

  .bg-mine.wrapper-transacton #Refresh {
    right: 0px;
    top: -5px;
  }

  .bg-mine.wrapper-transacton #Refres1 {
    right: 0px;
    top: 5px;
  }

  #tokens-page .righty h5,
  #tokens-page .righty h4 {
    font-size: 14px !important;
  }

  #tokens-page .form-box {
    padding: 0 !important;
  }

  .FormInputContainer__title {
    font-size: 14px !important;
  }

  .sidecontainer,
  .explorer-img {
    display: none;
  }
}